import { createAction, props } from '@ngrx/store';
import { BasicResponse } from 'common';
import { BusinessRep, BusinessProfile, CommonObjectResponse, MessageType, MessageTypesData, RegionOption } from './message-types.interface';

export enum SelfServiceMessageTypesHttpActionTypes {
    ClearMessageType = '[SelfServiceMessageTypes] Clear Message Type',
    GetSmsBuilderUserPermissions = '[SelfServiceMessageTypes] Get Sms Builder User Permissions',
    GetMessageTypesList = '[SelfServiceMessageTypes] Get Message Types List',
    CreateMessageType = '[SelfServiceMessageTypes] Create Message Type',
    GetMessageType = '[SelfServiceMessageTypes] Get Message Type',
    UpdateMessageType = '[SelfServiceMessageTypes] Update Message Type',
    GetUseCasesList = '[SelfServiceMessageTypes] Get Use Cases List',
    GetVolumeOptionsList = '[SelfServiceMessageTypes] Get Volume Options List',
    GetOptInOptionsList = '[SelfServiceMessageTypes] Get Opt In Options List',
    // Bussiness Profiles
    ClearBusinessProfile = '[SelfServiceMessageTypes] Clear Business Profile',
    GetBusinessProfilesList = '[SelfServiceMessageTypes] Get Business Profiles List',
    CreateBusinessProfile = '[SelfServiceMessageTypes] Create Business Profile',
    GetBusinessProfile = '[SelfServiceMessageTypes] Get Business Profile',
    UpdateBusinessProfile = '[SelfServiceMessageTypes] Update Business Profile',
    GetBusinessIndustryOptionsList = '[SelfServiceMessageTypes] Get Business Industry Options List', // NOTE: This action is currently not used. Functionality removed for next version.
    GetBusinessTypeOptionsList = '[SelfServiceMessageTypes] Get Business Type Options List', // NOTE: This action is currently not used. Functionality removed for next version.
    GetRegionOptionsList = '[SelfServiceMessageTypes] Get Region Options List',
    // Business Reps
    ClearBusinessRep = '[SelfServiceMessageTypes] Clear Business Rep',
    GetBusinessRepsList = '[SelfServiceMessageTypes] Get Business Reps List',
    CreateBusinessRep = '[SelfServiceMessageTypes] Create Business Rep',
    GetBusinessRep = '[SelfServiceMessageTypes] Get Business Rep',
    UpdateBusinessRep = '[SelfServiceMessageTypes] Update Business Rep',
    GetJobTitlesOptionsList = '[SelfServiceMessageTypes] Get Job Titles Options List',
}

export enum SelfServiceMessageTypesInnerActionTypes {
    GetSmsBuilderUserPermissionsSuccess = '[SelfServiceMessageTypes] Get Sms Builder User Permissions Success',
    GetSmsBuilderUserPermissionsError = '[SelfServiceMessageTypes] Get Sms Builder User Permissions Error',
    GetMessageTypesListSuccess = '[SelfServiceMessageTypes] Get Message Types List Success',
    GetMessageTypesListError = '[SelfServiceMessageTypes] Get Message Types List Error',
    CreateMessageTypeSuccess = '[SelfServiceMessageTypes] Create Message Type Success',
    CreateMessageTypeError = '[SelfServiceMessageTypes] Create Message Type Error',
    GetMessageTypeSuccess = '[SelfServiceMessageTypes] Get Message Type Success',
    GetMessageTypeError = '[SelfServiceMessageTypes] Get Message Type Error',
    UpdateMessageTypeSuccess = '[SelfServiceMessageTypes] Update Message Type Success',
    UpdateMessageTypeError = '[SelfServiceMessageTypes] Update Message Type Error',
    GetUseCasesListSuccess = '[SelfServiceMessageTypes] Get Use Cases List Success',
    GetUseCasesListError = '[SelfServiceMessageTypes] Get Use Cases List Error',
    GetVolumeOptionsListSuccess = '[SelfServiceMessageTypes] Get Volume Options List Success',
    GetVolumeOptionsListError = '[SelfServiceMessageTypes] Get Volume Options List Error',
    GetOptInOptionsListSuccess = '[SelfServiceMessageTypes] Get Opt In Options List Success',
    GetOptInOptionsListError = '[SelfServiceMessageTypes] Get Opt In Options List Error',
    // Bussiness Profiles
    GetBusinessProfilesListSuccess = '[SelfServiceMessageTypes] Get Business Profiles List Success',
    GetBusinessProfilesListError = '[SelfServiceMessageTypes] Get Business Profiles List Error',
    CreateBusinessProfileSuccess = '[SelfServiceMessageTypes] Create Business Profile Success',
    CreateBusinessProfileError = '[SelfServiceMessageTypes] Create Business Profile Error',
    GetBusinessProfileSuccess = '[SelfServiceMessageTypes] Get Business Profile Success',
    GetBusinessProfileError = '[SelfServiceMessageTypes] Get Business Profile Error',
    UpdateBusinessProfileSuccess = '[SelfServiceMessageTypes] Update Business Profile Success',
    UpdateBusinessProfileError = '[SelfServiceMessageTypes] Update Business Profile Error',
    GetBusinessIndustryOptionsListSuccess = '[SelfServiceMessageTypes] Get Business Industry Options List Success',
    GetBusinessIndustryOptionsListError = '[SelfServiceMessageTypes] Get Business Industry Options List Error',
    GetBusinessTypeOptionsListSuccess = '[SelfServiceMessageTypes] Get Business Type Options List Success',
    GetBusinessTypeOptionsListError = '[SelfServiceMessageTypes] Get Business Type Options List Error',
    GetRegionOptionsListSuccess = '[SelfServiceMessageTypes] Get Region Options List Success',
    GetRegionOptionsListError = '[SelfServiceMessageTypes] Get Region Options List Error',
    // Business Reps
    GetBusinessRepsListSuccess = '[SelfServiceMessageTypes] Get Business Reps List Success',
    GetBusinessRepsListError = '[SelfServiceMessageTypes] Get Business Reps List Error',
    CreateBusinessRepSuccess = '[SelfServiceMessageTypes] Create Business Rep Success',
    CreateBusinessRepError = '[SelfServiceMessageTypes] Create Business Rep Error',
    GetBusinessRepSuccess = '[SelfServiceMessageTypes] Get Business Rep Success',
    GetBusinessRepError = '[SelfServiceMessageTypes] Get Business Rep Error',
    UpdateBusinessRepSuccess = '[SelfServiceMessageTypes] Update Business Rep Success',
    UpdateBusinessRepError = '[SelfServiceMessageTypes] Update Business Rep Error',
    GetJobTitlesOptionsListSuccess = '[SelfServiceMessageTypes] Get Job Titles Options List Success',
    GetJobTitlesOptionsListError = '[SelfServiceMessageTypes] Get Job Titles Options List Error',
}

// ===== HTTP =====
export const ClearMessageType = createAction(SelfServiceMessageTypesHttpActionTypes.ClearMessageType);
export const GetSmsBuilderUserPermissions = createAction(SelfServiceMessageTypesHttpActionTypes.GetSmsBuilderUserPermissions);
export const GetMessageTypesList = createAction(SelfServiceMessageTypesHttpActionTypes.GetMessageTypesList, props<{ payload: { numResults: number, offset: number, order: string, sortBy: string, searchText: string, statusCode?: number, verificationCode?: number, currentPage: number } }>());
export const CreateMessageType = createAction(SelfServiceMessageTypesHttpActionTypes.CreateMessageType, props<{ payload: MessageType }>());
export const GetMessageType = createAction(SelfServiceMessageTypesHttpActionTypes.GetMessageType, props<{ payload: { messageTypeId: number } }>());
export const UpdateMessageType = createAction(SelfServiceMessageTypesHttpActionTypes.UpdateMessageType, props<{ payload: { messageTypeId: number, data: MessageType } }>());
export const GetUseCasesList = createAction(SelfServiceMessageTypesHttpActionTypes.GetUseCasesList);
export const GetVolumeOptionsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetVolumeOptionsList);
export const GetOptInOptionsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetOptInOptionsList);
// Bussiness Profiles
export const ClearBusinessProfile = createAction(SelfServiceMessageTypesHttpActionTypes.ClearBusinessProfile);
export const GetBusinessProfilesList = createAction(SelfServiceMessageTypesHttpActionTypes.GetBusinessProfilesList);
export const CreateBusinessProfile = createAction(SelfServiceMessageTypesHttpActionTypes.CreateBusinessProfile, props<{ payload: BusinessProfile }>());
export const GetBusinessProfile = createAction(SelfServiceMessageTypesHttpActionTypes.GetBusinessProfile, props<{ payload: { businessProfileId: number } }>());
export const UpdateBusinessProfile = createAction(SelfServiceMessageTypesHttpActionTypes.UpdateBusinessProfile, props<{ payload: { businessProfileId: number, data: BusinessProfile } }>());
export const GetBusinessIndustryOptionsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetBusinessIndustryOptionsList);
export const GetBusinessTypeOptionsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetBusinessTypeOptionsList);
export const GetRegionOptionsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetRegionOptionsList);
// Business Reps
export const ClearBusinessRep = createAction(SelfServiceMessageTypesHttpActionTypes.ClearBusinessRep);
export const GetBusinessRepsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetBusinessRepsList);
export const CreateBusinessRep = createAction(SelfServiceMessageTypesHttpActionTypes.CreateBusinessRep, props<{ payload: BusinessRep }>());
export const GetBusinessRep = createAction(SelfServiceMessageTypesHttpActionTypes.GetBusinessRep, props<{ payload: { businessRepId: number } }>());
export const UpdateBusinessRep = createAction(SelfServiceMessageTypesHttpActionTypes.UpdateBusinessRep, props<{ payload: { businessRepId: number, data: BusinessRep } }>());
export const GetJobTitlesOptionsList = createAction(SelfServiceMessageTypesHttpActionTypes.GetJobTitlesOptionsList);

// ====== INNER ======
export const GetSmsBuilderUserPermissionsSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetSmsBuilderUserPermissionsSuccess, props<{ payload: string[] }>());
export const GetSmsBuilderUserPermissionsError = createAction(SelfServiceMessageTypesInnerActionTypes.GetSmsBuilderUserPermissionsError, props<{ payload: BasicResponse }>());
export const GetMessageTypesListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetMessageTypesListSuccess, props<{ payload: MessageTypesData }>());
export const GetMessageTypesListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetMessageTypesListError, props<{ payload: BasicResponse }>());
export const CreateMessageTypeSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.CreateMessageTypeSuccess, props<{ payload: MessageType }>());
export const CreateMessageTypeError = createAction(SelfServiceMessageTypesInnerActionTypes.CreateMessageTypeError, props<{ payload: BasicResponse }>());
export const GetMessageTypeSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetMessageTypeSuccess, props<{ payload: MessageType }>());
export const GetMessageTypeError = createAction(SelfServiceMessageTypesInnerActionTypes.GetMessageTypeError, props<{ payload: BasicResponse }>());
export const UpdateMessageTypeSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.UpdateMessageTypeSuccess, props<{ payload: MessageType }>());
export const UpdateMessageTypeError = createAction(SelfServiceMessageTypesInnerActionTypes.UpdateMessageTypeError, props<{ payload: BasicResponse }>());
export const GetUseCasesListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetUseCasesListSuccess, props<{ payload: Array<CommonObjectResponse> }>());
export const GetUseCasesListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetUseCasesListError, props<{ payload: BasicResponse }>());
export const GetVolumeOptionsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetVolumeOptionsListSuccess, props<{ payload: Array<CommonObjectResponse> }>());
export const GetVolumeOptionsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetVolumeOptionsListError, props<{ payload: BasicResponse }>());
export const GetOptInOptionsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetOptInOptionsListSuccess, props<{ payload: Array<CommonObjectResponse> }>());
export const GetOptInOptionsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetOptInOptionsListError, props<{ payload: BasicResponse }>());
// Bussiness Profiles
export const GetBusinessProfilesListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessProfilesListSuccess, props<{ payload: Array<BusinessProfile> }>());
export const GetBusinessProfilesListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessProfilesListError, props<{ payload: BasicResponse }>());
export const CreateBusinessProfileSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.CreateBusinessProfileSuccess, props<{ payload: BusinessProfile }>());
export const CreateBusinessProfileError = createAction(SelfServiceMessageTypesInnerActionTypes.CreateBusinessProfileError, props<{ payload: BasicResponse }>());
export const GetBusinessProfileSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessProfileSuccess, props<{ payload: BusinessProfile }>());
export const GetBusinessProfileError = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessProfileError, props<{ payload: BasicResponse }>());
export const UpdateBusinessProfileSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.UpdateBusinessProfileSuccess, props<{ payload: BusinessProfile }>());
export const UpdateBusinessProfileError = createAction(SelfServiceMessageTypesInnerActionTypes.UpdateBusinessProfileError, props<{ payload: BasicResponse }>());
export const GetBusinessIndustryOptionsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessIndustryOptionsListSuccess, props<{ payload: Array<CommonObjectResponse> }>());
export const GetBusinessIndustryOptionsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessIndustryOptionsListError, props<{ payload: BasicResponse }>());
export const GetBusinessTypeOptionsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessTypeOptionsListSuccess, props<{ payload: Array<CommonObjectResponse> }>());
export const GetBusinessTypeOptionsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessTypeOptionsListError, props<{ payload: BasicResponse }>());
export const GetRegionOptionsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetRegionOptionsListSuccess, props<{ payload: Array<RegionOption> }>());
export const GetRegionOptionsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetRegionOptionsListError, props<{ payload: BasicResponse }>());
// Business Reps
export const GetBusinessRepsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessRepsListSuccess, props<{ payload: Array<BusinessRep> }>());
export const GetBusinessRepsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessRepsListError, props<{ payload: BasicResponse }>());
export const CreateBusinessRepSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.CreateBusinessRepSuccess, props<{ payload: BusinessRep }>());
export const CreateBusinessRepError = createAction(SelfServiceMessageTypesInnerActionTypes.CreateBusinessRepError, props<{ payload: BasicResponse }>());
export const GetBusinessRepSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessRepSuccess, props<{ payload: BusinessRep }>());
export const GetBusinessRepError = createAction(SelfServiceMessageTypesInnerActionTypes.GetBusinessRepError, props<{ payload: BasicResponse }>());
export const UpdateBusinessRepSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.UpdateBusinessRepSuccess, props<{ payload: BusinessRep }>());
export const UpdateBusinessRepError = createAction(SelfServiceMessageTypesInnerActionTypes.UpdateBusinessRepError, props<{ payload: BasicResponse }>());
export const GetJobTitlesOptionsListSuccess = createAction(SelfServiceMessageTypesInnerActionTypes.GetJobTitlesOptionsListSuccess, props<{ payload: Array<CommonObjectResponse> }>());
export const GetJobTitlesOptionsListError = createAction(SelfServiceMessageTypesInnerActionTypes.GetJobTitlesOptionsListError, props<{ payload: BasicResponse }>());